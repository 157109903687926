<template>
  <div class="vx-col md:w-1/1 w-full">
    <vx-card title="Users">
      <vs-button
        class="mt-2"
        color="primary"
        type="border"
        icon-pack="feather"
        icon="icon-plus"
        @click="handleCreate()"
      >Create</vs-button>
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <vs-table
          search
          stripe
          border
          description
          :sst="true"
          :data="table.data"
          :max-items="table.length"
          :total="table.total"
          @search="handleSearch"
          @change-page="handleChangePage"
          @sort="handleSort"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2">{{this.table.start}} - {{this.table.end}} of {{this.table.total}}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in table.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{item}}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
          <template slot="thead">
            <vs-th>No</vs-th>
            <vs-th>Action</vs-th>
            <!-- <vs-th sort-key="company_name">Company</vs-th> -->
            <vs-th sort-key="work_group_name">Work Group</vs-th>
            <vs-th sort-key="work_id_number">Work ID Number</vs-th>
            <vs-th sort-key="id_number">ID Number</vs-th>
            <vs-th sort-key="name">Name</vs-th>
            <vs-th sort-key="address">Address</vs-th>
            <vs-th sort-key="email">Email</vs-th>
            <vs-th sort-key="Mobile">Mobile</vs-th>
            <vs-th sort-key="Mobile">Code External</vs-th>
            <vs-th sort-key="status">Status Active</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>{{ indextr+1 }}</vs-td>
              <vs-td>
                                <span style="display: flex">
                                    <vs-button
                                      class="mr-2"
                                      radius
                                      v-on:click="handleEdit(data[indextr].id)"
                                      color="primary"
                                      type="border"
                                      icon-pack="feather"
                                      icon="icon-edit"
                                    ></vs-button>
                                  <!-- <vs-button
                                      radius
                                      v-on:click="handleDelete(data[indextr].id)"
                                      color="danger"
                                      type="border"
                                      icon-pack="feather"
                                      icon="icon-trash"
                                  ></vs-button> -->
                                </span>
              </vs-td>
              <!-- <vs-td :data="data[indextr].company_name">{{ data[indextr].company_name }}</vs-td> -->
              <vs-td :data="data[indextr].work_group_name">{{ data[indextr].work_group_name }}</vs-td>
              <vs-td :data="data[indextr].work_id_number">{{ data[indextr].work_id_number }}</vs-td>
              <vs-td :data="data[indextr].id_number">{{ replaceLastThreeChars(data[indextr].id_number) }}</vs-td>
              <vs-td :data="data[indextr].name">{{ data[indextr].name }}</vs-td>
              <vs-td :data="data[indextr].address">{{ data[indextr].address }}</vs-td>
              <vs-td :data="data[indextr].email">{{ replaceLastThreeCharsEmail(data[indextr].email) }}</vs-td>
              <vs-td :data="data[indextr].mobile">{{ replaceLastThreeChars(data[indextr].mobile) }}</vs-td>
              <vs-td :data="data[indextr].code_external">{{ data[indextr].code_external }}</vs-td>
              <vs-td>
                                <span style="display: flex">
                                    <feather-icon v-if="data[indextr].status_active" icon="CheckIcon" svgClasses="w-5 h-5 text-success stroke-current"/>
                                    <feather-icon v-else icon="XIcon" svgClasses="w-5 h-5 text-danger stroke-current"/>
                                </span>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          style="padding-top:5px"
          :total="table.totalPage"
          v-model="setPage"
        />
      </div>
    </vx-card>
  </div>
</template>

<script>
export default {
  data(){
    return {
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      deleteId: null,
      customerId: null,
      table: {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "deleted_at",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0
      }
    };
  },
  methods: {
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("api/oms/v1/sfa-master/user-personals", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort
          }
        })
        .then(resp => {
          if (resp.status == "success") {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            console.log(resp.message);
          }
        });
    },
    setStartEnd() {
      let valStart = this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.table.totalSearch < this.table.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleEdit(val) {
      this.$router.push("/setting/user/edit/" + val);
    },
    handleDelete(val) {
      this.deleteId = val;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Please confirm to delete this user data",
        accept: this.acceptDelete
      });
    },
    acceptDelete() {
      this.$vs.loading();
      this.$http
        .delete("/api/v1/setting/user-personal/" + this.deleteId)
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The user data was successfully deleted",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
          this.getData();
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleCreate() {
      this.$router.push("/setting/user/add");
    },
    replaceLastThreeChars(str) {
      var replacement = "***"
      if (str.length < 3) {
        return replacement.slice(0, str.length);
      }
      return str.slice(0, -3) + replacement;
    },
    replaceLastThreeCharsEmail(email){
      var replacement = "***"
      var a = email.split("@");
      var b = a[0];
      if (b.length < 3) {
        return replacement.slice(0, b.length);
      }
      return b.slice(0, -3) + replacement +"@"+ a[1]
    }
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      }
    }
  }
}
</script>
